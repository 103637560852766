import Rails from "@rails/ujs"
import * as utilities from "../src/utilities.js"

import { Controller } from "stimulus"

const ROW_SELECTOR = "tr.inventory-row";
const ERROR_BORDER = "error-border";

const ITEM_TYPE_INPUT_SELECTOR = '[name="item_types[]"]'


export default class extends Controller{

  static targets = ["form", "reference", "referenceWarning", "date", "totalQuantity", "totalTotal", "submitErrors"]

  connect(){
    this.emptyRowHtml = this.lastInventoryRow().outerHTML;

    $(ITEM_TYPE_INPUT_SELECTOR).selectpicker();
  }

  lastInventoryRow(){
    return document.querySelector(`${ROW_SELECTOR}:last-child`);
  }

  handleSubmitClick(event){

    event.preventDefault();

    if (!this.validateSale(event))
      return;

    const form = this.formTarget;

    Rails.ajax({
      type: form.method,
      url: form.action,
      data: new FormData(form),
      success: (response) => {this.handleSubmitResponse(response)},
    });

  }

  handleSubmitResponse(response){

    if (response.success)
      Turbo.visit(response.url);
    else {
      this.submitErrorsTarget.innerHTML = response.html;
      this.submitErrorsTarget.style.display = "block";
    }

  }

  validateReference(event){

    const reference = this.referenceTarget.value.trim();

    const formData = new FormData();
    formData.append("reference", reference);

    Rails.ajax({
      type: "POST",
      url: event.params.validateReferenceUrl,
      data: formData,
      success: (response) => {this.displayReferenceWarning(response)},
    });
  }

  validateSale(event){

    var saleValid = true;

    if (this.dateTarget.value.length == 0) {
      saleValid = false;
      this.setValidation(this.dateTarget, false);
    }

    var rowValid;

    this.rows().forEach(function(row){
      rowValid = this.validateRow(row, null);

      if (!rowValid)
        saleValid = false;
    }, this);

    return saleValid;
  }

  displayReferenceWarning(data){

    const element = this.referenceWarningTarget;

    if (data.message == "")
      element.classList.add("hidden");
    else
      element.classList.remove("hidden");

    element.textContent = data.message;

  }

  money2str(x){
    if (isNaN(x))
      return "";
    else
      return x.toFixed(2);
  }

  changeHandler(event){

    // this validates any data that can be validated within its own group, including each row against itself
    // sale-level validations against current inventory over the season are not done until the sale is submitted,
    // for performance reasons.  This could change if it doesn't prove to be usable.

    if (event.target == this.referenceTarget){
      this.validateReference(event);
      return;
    }

    const eventRow = this.eventRow(event);

    if (eventRow == null)
      return;

    const saleData = this.getSaleData(eventRow);

    this.validateRow(eventRow, event.target);

    this.setValue(this.priceInput(eventRow), this.money2str(saleData.eventRowData.price));
    this.setContent(this.totalSpan(eventRow), this.money2str(saleData.eventRowData.total));

    this.rows().forEach(function(row, i){
      this.setContent(this.availableSpan(row), saleData.lines[i].available);
    }, this);

    this.setContent(this.totalQuantityTarget, saleData.totals.quantity);
    this.setContent(this.totalTotalTarget, this.money2str(saleData.totals.total));

    var lastRow = this.lastInventoryRow();

    // if this is the last row, add another one
    if (eventRow == lastRow){
      lastRow.insertAdjacentHTML("afterend", this.emptyRowHtml);
      lastRow = this.lastInventoryRow();
      const select = this.itemTypeInput(lastRow);
      $(select).selectpicker();
    }

  }

  validateRow(row, changedInput){

    // if the numerics are empty, we're good regardless of whether item type is set
    // if all three are full, we're good

    const itemTypeInput = this.itemTypeInput(row);
    const quantityInput = this.quantityInput(row);
    const priceInput = this.priceInput(row);

    const itemType = itemTypeInput.value.length > 0;
    const quantity = quantityInput.value.length > 0;
    const price = priceInput.value.length > 0;

    const numericsEmpty = !quantity && !price;
    const noneEmpty = itemType && quantity && price;
    const rowValid = numericsEmpty || noneEmpty;

    if (rowValid) { 
      this.setValidation(itemTypeInput, true);
      this.setValidation(quantityInput, true);
      this.setValidation(priceInput, true);
    }
    else {
      if (changedInput != null)
        this.setValidation(changedInput, true);
      else {
        this.setValidation(itemTypeInput, itemType);
        this.setValidation(quantityInput, quantity);
        this.setValidation(priceInput, price);
      }
    }

    return rowValid;

  }

  setValidation(input, valid){

    var element = input;

    if (input.classList.contains("bootstrap-select"))
      $(input).selectpicker('setStyle', ERROR_BORDER, (valid ? "remove" : "add"));
    else {
      if (valid)
        input.classList.remove(ERROR_BORDER);
      else
        input.classList.add(ERROR_BORDER);
    }
  }

  setValue(element, value){
    if (!isNaN(value))
      element.value = value;
  }

  setContent(element, value){
    if (!isNaN(value))
      element.textContent = value;
  }

  eventRow(event){
    return(event.target.closest(ROW_SELECTOR));
  }

  itemTypeInput(row){
    return(row.querySelector(ITEM_TYPE_INPUT_SELECTOR));
  }

  availableSpan(row){
    return(row.querySelector('[name="availables[]"]'));
  }

  quantityInput(row){
    return(row.querySelector('[name="quantities[]"]'));
  }

  priceInput(row){
    return(row.querySelector('[name="prices[]"]'));
  }

  totalSpan(row){
    return(row.querySelector('[name="totals[]"]'));
  }

  rows(){
    return document.querySelectorAll(ROW_SELECTOR);
  }

  rowData(row){

    const itemTypeInput = this.itemTypeInput(row);
    const quantityInput = this.quantityInput(row);
    const priceInput = this.priceInput(row);

    var selectedOption, rawGrossAvailable;
    const itemTypeId = itemTypeInput.value

    if (itemTypeId.length == 0)
      rawGrossAvailable = null;
    else {
      const selectedOption = itemTypeInput.querySelector(`option[value="${itemTypeId}"]`);
      rawGrossAvailable = selectedOption.getAttribute("quantity");
    }

    const data = {
      itemTypeId: itemTypeInput.value,
      rawGrossAvailable: rawGrossAvailable,
      rawQuantity: quantityInput.value,
      rawPrice: priceInput.value,
    };

    data.empty = (data.itemTypeId.length == 0)
      && (data.rawQuantity.length == 0)
      && (data.rawPrice.length == 0)

    data.grossAvailable = parseInt(data.rawGrossAvailable);
    data.quantity = parseInt(data.rawQuantity);
    data.price = parseFloat(data.rawPrice);
    data.total = data.quantity*data.price;

    data.formattedPrice = this.money2str(data.price);
    data.formattedTotal = this.money2str(data.total);

    return data;
  }

  getSaleData(eventRow){

    const saleData = {};
    saleData.lines = [];

    var rowData;

    saleData.totals = {
      quantity: 0,
      total: 0,
    }

    const controller = this;

    this.rows().forEach(function(row){
      rowData = this.rowData(row);

      if (row == eventRow)
        saleData.eventRowData = rowData;

      saleData.lines.push(rowData);

      if (!isNaN(rowData.quantity))
        saleData.totals.quantity += rowData.quantity;

      if (!isNaN(rowData.total))
        saleData.totals.total += rowData.total;
    }, this);

    saleData.lines.forEach(function(line){

      line.available = line.grossAvailable;

      if (!isNaN(line.grossAvailable)){
        saleData.lines.forEach(function(line2){
          if ((line.itemTypeId == line2.itemTypeId) && !isNaN(line2.quantity))
            line.available -= line2.quantity;
        });
      }

    });

    return saleData;
  }

  storeInputState(event){

    const input = event.target;

    if (input.oldValue == null)
      input.oldValue = input.value;
  }

  enforceInteger(event){
    this.enforceText(event, /^[0-9]*$/);
  }

  enforceFloat(event){
    this.enforceText(event, /^\d*(\d.)?\d{0,2}$/);
  }

  enforceText(event, regex){

    const input = event.target;

    if (regex.test(input.value))
      input.oldValue = input.value;
    else
      input.value = input.oldValue;
  }

}


