import { Controller } from "stimulus"
import Rails from "@rails/ujs"

const ADD    = "add";
const REMOVE = "remove";
const SET    = "set";

export default class extends Controller {

  adjustmentTextKeyDown(event){
    const key = event.key;

    if ((key.length == 1) && !key.match(/\d/))
      event.preventDefault();
  }

  addItems(event){
    this.adjustItems(event, ADD);
  }

  removeItems(event){
    this.adjustItems(event, REMOVE);
  }

  setItems(event){
    this.adjustItems(event, SET);
  }

  adjustItems(event, mode){

    const adjustmentRow = event.target.closest("tr");

    const inventoryCell = adjustmentRow.querySelector("[name=inventory_item_count]");
    const shipmentCell = adjustmentRow.querySelector("[name=shipment_item_count]");
    const adjustmentTextInput = adjustmentRow.querySelector("[name=adjustment_quantity]");
    const adjustmentHiddenInput = adjustmentRow.querySelector('[name="adjustments[]"]');

    const inventoryTotalCell = document.querySelector("#inventory_total_count");
    const shipmentTotalCell = document.querySelector("#shipment_total_count");

    const inventoryItemCount = this.getValue(inventoryCell);
    const shipmentItemCount = this.getValue(shipmentCell);

    var adjustQuantity = this.parseInt(adjustmentTextInput.value);

    if (mode == REMOVE)
      adjustQuantity = -adjustQuantity;
    else if (mode == SET)
      adjustQuantity -= this.parseInt(shipmentCell.textContent);

    if (shipmentItemCount + adjustQuantity < 0){
      this.reportError(adjustmentTextInput, `Removing ${Math.abs(adjustQuantity)} items from the shipment would cause this item type to go negative.`);
      return
    }

    if (inventoryItemCount - adjustQuantity < 0){
      this.reportError(adjustmentTextInput, `Removing ${adjustQuantity} items from inventory would cause this item type to go negative.`);
      return
    }

    this.adjustElementValue(inventoryCell, -adjustQuantity);
    this.adjustElementValue(shipmentCell, adjustQuantity);

    this.adjustElementValue(inventoryTotalCell, -adjustQuantity);
    this.adjustElementValue(shipmentTotalCell, adjustQuantity);

    this.adjustElementValue(adjustmentHiddenInput, adjustQuantity);
    adjustmentTextInput.value = "";
  }

  adjustElementValue(element, adjustQuantity){
    const initialQuantity = this.getValue(element);
    const newQuantity = initialQuantity + adjustQuantity;
    this.setValue(element, newQuantity)
  }

  isInput(element){
    return (element.tagName == "INPUT");
  }

  getValue(element){
    var sValue;

    if (this.isInput(element))
      sValue = element.value;
    else
      sValue = element.textContent;

    return this.parseInt(sValue);
  }

  setValue(element, value){

    const sValue = value.toString();

    if (this.isInput(element))
      element.value = sValue;
    else
      element.textContent = sValue;
  }

  reportError(input, message){
    input.select();
    input.focus();
    alert(message);
  }

  parseInt(s){
    return (parseInt(s) || 0);
  }

}
