import Rails from "@rails/ujs"
import * as utilities from "./utilities.js"

const ID_ATTRIBUTE = "data-latest-notification-id"

function notificationsDiv(){
  return document.getElementById("user-notifications-div");
}

function alertDiv() {
  return notificationsDiv().querySelector("div");
}

function closeButton() {
  return notificationsDiv().querySelector("button");
}

function notificationsIndexBody(){
  return document.getElementById("user-notifications-index-body")
}

export function updateNotificationsDiv(innerHTML) {
  notificationsDiv().innerHTML = innerHTML
  initialize();

  if (notificationsIndexBody() != null){
    Rails.ajax({
      type: "GET",
      url: notificationsIndexBody().getAttribute("index-rows-path"),
      success: (response) => {notificationsIndexBody().innerHTML = response},
    });
  }
}

function updateIndexRows(html){
  notificationsIndexBody().innerHTML = html;
}

function dismissNotification(){
  const notificationId = parseInt(alertDiv().getAttribute(ID_ATTRIBUTE));
  window.app.userNotificationsChannel.dismissNotification(notificationId);  
}

export function initialize(){
  const button = closeButton();

  if (button != null)
    button.addEventListener("click", dismissNotification);
}

