import { Controller } from "stimulus"
import { ProgressBar } from "../src/progress_bar.js"

export class ProgressController extends Controller{

  addProgressBar(progressPrefix, afterFinish){

    if (this.progressBars == null)
      this.progressBars = [];

    this.progressBars.push(new ProgressBar(progressPrefix, afterFinish));
  }

  connect(){
    this.progressBars.forEach((progressBar)=>progressBar.subscribe());
  }

  disconnect(){
    this.progressBars.forEach((progressBar)=>progressBar.unsubscribe());
  }

}

