import Rails from "@rails/ujs"

export function subscribe()
{
  const shipmentId = updateDiv().getAttribute("data-shipment-id");
  const channelInfo = window.app.shipmentsShowChannelInfo;

  if (channelInfo != null) {                      // if we navigate to another one but the channel is already there, then
    if (channelInfo.shipmentId == shipmentId)     // we need to unsubscribe unless it's for this shipment id
      return channelInfo.subscription;
    else
      unsubscribe();
  }

  const subscription = window.app.consumer.subscriptions.create({
    channel: "ShipmentsUpdateChannel",
    shipment_id: shipmentId,
  },
  {
    received: update,
  })

  window.app.shipmentsShowChannelInfo = {
    subscription: subscription,
    shipmentId: shipmentId,
  }

  return subscription;

}

export function unsubscribe(){
  if (window.app.shipmentsShowChannelInfo != null){
    window.app.shipmentsShowChannelInfo.subscription.unsubscribe()
    window.app.shipmentsShowChannelInfo = null;
  }
}

function updateDiv(){
  return document.getElementById("shipments-show-update-div");
}

function updateOrRedirect(response){

  if (response == null){
    const indexUrl = updateDiv().getAttribute("data-index-url");
    window.location.href = indexUrl;
  }
  else
    updateDiv().innerHTML = response;
}

function update() {

  const url = updateDiv().getAttribute("data-update-url");

  Rails.ajax({
    type: "GET",
    url: url,
    success: updateOrRedirect,
  });

}
