import * as receiveShipmentsShow from "../src/receive_shipments_show.js"

const SERVER_CHANNEL_CLASS = "ReceiveShipmentsShowChannel";
const SERVER_SET_RECEIVED_QUANTITY = "set_received_quantity";
const SERVER_ADD_RECEIVED_QUANTITY = "add_received_quantity";

export function subscribe(shipment_id)
{
  if (window.app.receiveShipmentsShowSubscription == null) {

    window.app.receiveShipmentsShowSubscription = window.app.consumer.subscriptions.create({
      channel: SERVER_CHANNEL_CLASS,
      shipment_id: shipment_id,
    },
    {
      received: function(data){
        if ((data.action == SERVER_SET_RECEIVED_QUANTITY) || (data.action == SERVER_ADD_RECEIVED_QUANTITY)){
          receiveShipmentsShow.receiveSetReceivedItems(data);
        }
      },

      sendSetReceivedItems: function(data){
        this.perform(SERVER_SET_RECEIVED_QUANTITY, data);
      },

      sendAddReceivedItems: function(data){
        this.perform(SERVER_ADD_RECEIVED_QUANTITY, data);
      },
    })
  }
  
  return window.app.receiveShipmentsShowSubscription;
}

export function unsubscribe(){
  if (window.app.receiveShipmentsShowSubscription != null) {
    window.app.receiveShipmentsShowSubscription.unsubscribe();
    window.app.receiveShipmentsShowSubscription = null;
  }
}

