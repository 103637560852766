import * as receiveShipments from "../src/receive_shipments.js"

export function subscribe()
{
  if (window.app.packageChannel == null) {

    window.app.packageChannel = window.app.consumer.subscriptions.create({
      channel: "PackageChannel"
    },
    {
      // do note that the received HTML contains window.app.packageData in the
      // form of javascript reading a JSON string
      received: function(data){
        receiveShipments.updateTableBody(data["table_body"])
      },

      receive_package: function(tracking_number){
        this.perform("receive_package", {tracking_number: tracking_number});
      },
    })
  }
  
  return window.app.packageChannel;
}

export function unsubscribe(){
  if (window.app.packageChannel != null) {
    window.app.packageChannel.unsubscribe();
    window.app.packageChannel = null;
  }
}

