// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

// somehow it can't properly parse the forward-called function this.getConfig when using import
// so I set it up to use require()

// initiates the websocket connection to be used by various channels
// stores the connection globally to avoid duplication

const actionCable = require("@rails/actioncable")

if (window.app.consumer == null)
  window.app.consumer = actionCable.createConsumer();

