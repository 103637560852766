import * as userNotifications from "../src/user_notifications.js"

export function subscribe()
{
  if (window.app.userNotificationsChannel == null) {

    window.app.userNotificationsChannel = window.app.consumer.subscriptions.create({
      channel: "UserNotificationsChannel"
    },
    {
      received: function(innerHTML){
        userNotifications.updateNotificationsDiv(innerHTML)
      },

      dismissNotification: function(latestNotificationId){
        this.perform("dismiss_notification", {latest_notification_id: latestNotificationId});
      },
    })
  }
  
  return window.app.userNotificationsChannel;
}

