import * as utilities from "./utilities.js"

const SUCCESS_FREQUENCY = 440;
const FAILURE_FREQUENCY = 180;

// window.simulateScan = function(){
//   window.app.scanString = "12345";
//   var event = new KeyboardEvent('keydown', {key: "Enter"}); 
//   document.dispatchEvent(event); 
// }

// note that window.app.packageData is set by means of the view passing it embedded
// as a <script> element that reads a JSON string when it gets executed.  Not my
// proudest moment.

export function updateTableBody(html) {
  utilities.setHTML("received_shipments_body", html);
}

function displayScanString(scanString) {
  utilities.setHTML('tracking_number', scanString);
}

export function pasteHandler(event){
  const text = event.clipboardData.getData("text");
  var key;

  for (var i=0; i<text.length; i++) {

    key = text[i];

    if (key == "\n")
      key = "Enter";

    keydownHandler({key: key}); // call keydown handler, passing an object that responds to 'key'.  A little uncomfortable?
  }

  event.preventDefault();
}

function isDigit(s){

  if (s.length != 1)
    return false;

  const charCode = s.charCodeAt(0);

  return((charCode >= 48) && (charCode <= 57));  // 0 to 9 inclusive
}

export function keydownHandler(event){

  var scanString = window.app.scanString; // work on a copy for readability and isolation of globals

  if (scanString == null)
    scanString = "";

  const key = event.key;

  if (isDigit(key)) {
    scanString += key;

    if (scanString.length > 12)
      scanString = scanString.slice(-12);

    displayScanString(scanString);
  }
  else if ((key == "Delete") || (key == "Backspace")) {
    if (scanString.length > 0) {
      scanString = scanString.slice(0,-1);
      displayScanString(scanString);
    }
  }
  else if (key == "Enter"){
    const _package = window.app.packageData[scanString];

    var frequency = FAILURE_FREQUENCY;
    var masterTrackingNumber = "";
    var shipmentNumber = "";

    if (_package != null) {
      frequency = SUCCESS_FREQUENCY;
      masterTrackingNumber = _package.master_tracking_number;
      shipmentNumber = _package.shipment_number;
      window.app.packageChannel.receive_package(scanString);
    }

    beep(frequency);
    utilities.setHTML('master_tracking_number', masterTrackingNumber);
    utilities.setHTML('shipment_number', shipmentNumber);

    scanString = ""
  }

  window.app.scanString = scanString; // store copy back into global
}

function beep(frequency){

  var beepObject = window.app.beepObject;   // grab pre-existing beep object

  var gain;
  var context;

  if (beepObject == null){                  // if there was no pre-existing beep object
    context = new AudioContext();               // create audio context
    gain = new GainNode(context, {gain: 1});    // create gain node in case we want to quiet it down?
    gain.connect(context.destination);          // connect output of gain node to context destination

    beepObject = {context: context, gain: gain};// put it into the previously nonexistent object

    window.app.beepObject = beepObject;         // save a reference to it on the window object
  }
  else {                                    // if there was a pre-existing object, grab its properties
    context = beepObject.context;
    gain = beepObject.gain
  }

  // note that oscillators are explicitly single use objects.  You can only call start on it once
  const oscillator = new OscillatorNode(context, {type: "square", frequency: frequency}); // create an oscillator
  oscillator.connect(gain);                                                               // connect its output to the gain node

  const duration = 200;
  const start_time = context.currentTime;
  const stop_time = start_time + duration/1000;

  oscillator.start(start_time);     // start and stop the oscillator
  oscillator.stop(stop_time);
}

