import { Controller } from 'stimulus'; 
export default class extends Controller {

  static targets = [
    "createLabelRadio",
    "uploadLabelRadio",
    "noLabelRadio",
    "labelUploadDiv",
    "packageCountDiv",
    "emailDiv",
    ];

  connect() {
    this.shippingModeChange(null);
  }
  
  shippingModeChange(event) {

    var labelUploadHidden = true;
    var packageCountHidden = true;
    var emailHidden = true;

    if (this.createLabelRadioTarget.checked){
      packageCountHidden = false;
      emailHidden = false;
    }
    else if (this.uploadLabelRadioTarget.checked)
      labelUploadHidden = false;
    else if (this.noLabelRadioTarget.checked)
      packageCountHidden = false;

    this.labelUploadDivTarget.hidden = labelUploadHidden;
    this.requireChildInputs(this.labelUploadDivTarget, !labelUploadHidden);

    this.packageCountDivTarget.hidden = packageCountHidden;
    this.requireChildInputs(this.packageCountDivTarget, !packageCountHidden);

    this.emailDivTarget.hidden = emailHidden;  // none of these are ever required


  }

  requireChildInputs(parent, requireFlag){
    parent.querySelectorAll("input").forEach((childInput) => childInput.required = requireFlag);
  }
  
}

