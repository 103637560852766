import Rails from "@rails/ujs"

export function subscribe()
{
  if (window.app.shipmentsIndexChannelSubscriptions == null) {

    window.app.shipmentsIndexChannelSubscriptions = window.app.consumer.subscriptions.create({
      channel: "ShipmentsUpdateChannel"
    },
    {
      received: update,
    })
  }
  
  return window.app.shipmentsIndexChannelSubscriptions;
}

export function unsubscribe(){
  if (window.app.shipmentsIndexChannelSubscriptions != null) {
    window.app.shipmentsIndexChannelSubscriptions.unsubscribe();
    window.app.shipmentsIndexChannelSubscriptions = null;
  }
}

function update() {

  const updateDiv = document.getElementById("shipments-index-update-div");
  const url = updateDiv.getAttribute("data-update-url");

  Rails.ajax({
    type: "GET",
    url: url,
    success: (response) => {updateDiv.innerHTML = response},
  });

}
