export class ProgressBar{

  constructor(progressPrefix, afterFinish){
    this.progressPrefix = progressPrefix;
    this.afterFinish = afterFinish;
  }

  subscribe()
  {
    this.outerDiv = document.querySelector(`div.progress[data-progress-id^=${this.progressPrefix}]`);
    this.outerText = this.outerDiv.querySelector(".progress-text");
    this.innerDiv = this.outerDiv.querySelector(".progress-bar");
    this.innerText = this.innerDiv.querySelector(".progress-bar-text");

    this.progressId = this.outerDiv.getAttribute("data-progress-id");
    this.messageTemplate = this.outerDiv.getAttribute("data-progress-message") || "";

    this.subscription = window.app.consumer.subscriptions.create({
      channel: "ProgressChannel",
      progress_id: this.progressId,
    },
    {
      progressBar: this,
      received: function(data){
        // kind of weird -- in this function, "this" refers to the ActionCable.Subscription
        // object.  That is, the one that will be returned by this create() function.  Note
        // that we set the "progressBar" object in the line above, as part of this "mixin"
        // argument to create().  I only figured out how to do this by reading their code,
        // so I consider it to be a bit on the brittle side...
        switch(data.action){
          case "start":
            this.progressBar.start(data.max);
            break;
          case "set":
            this.progressBar.set(data.value);
            break;
          case "finish":
            this.progressBar.finish(data.other);
        }
      },
    });
  }

  unsubscribe(){
    this.subscription.unsubscribe();
  }

  // note that bootstrap 3 has an outer div that sets total size, and an inner div
  // that sets completed size

  start(max){
    this.max = max;

    this.outerDiv.style.visibility = "visible";

    this.innerDiv.style.color = window.getComputedStyle(this.outerDiv).backgroundColor;
    this.outerDiv.style.color = window.getComputedStyle(this.innerDiv).backgroundColor;
    
    this.innerDiv.style.fontSize = window.getComputedStyle(this.outerDiv).fontSize;

    this.set(0);
  }

  set(value){

    this.innerDiv.style.width = `${this.percent(value)}%`;

    const s = this.progressMessage(value);

    this.innerText.textContent = s;
    this.outerText.textContent = s;
  }

  finish(data){
    this.outerDiv.style.visibility = "hidden";

    if (this.afterFinish != null)
      this.afterFinish(data);
  }

  percent(value){
    return Math.round(value/this.max*100);
  }

  progressMessage(value){
    var s = this.messageTemplate;

    s = s.replaceAll("{index}", value.toString());
    s = s.replaceAll("{max}", this.max.toString());
    s = s.replaceAll("{percent}", this.percent(value).toString());

    return s;
  }

}