import { Controller } from "stimulus"

export default class extends Controller{

  static targets = ["includeZerosForm"]

  includeZerosChange(event){
    this.includeZerosFormTarget.submit();
  }
}

