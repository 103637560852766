import Rails from "@rails/ujs"
import * as utilities from "../src/utilities.js"

import { Controller } from "stimulus"

export default class extends Controller{

  mergeableChangeHandler(event){
    Rails.ajax({
      type: "GET",
      url: event.params.mergeableUrl,
      data: `checked=${event.target.checked}`,
      success: null,
    });
  }
}