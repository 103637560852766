import { Controller } from 'stimulus'; 
export default class extends Controller {

  static targets = ["userTypeSelect", "customerSelect"];

  connect() {
    this.userTypeChange(null);
  }
  
  userTypeChange(event) {

    const labelSelector = `label[for=${this.customerSelectTarget.id}]`;
    const label = document.querySelector(labelSelector);

    const hide = !this.userTypeSelectTarget.value.toLowerCase().includes("customer");

    label.hidden = hide;
    this.customerSelectTarget.hidden = hide;
  }
  
}

