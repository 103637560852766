import Rails from "@rails/ujs"
import * as utilities from "../src/utilities.js"

import { Controller } from "stimulus"

const ROW_SELECTOR = "tr.inventory-row";
const ERROR_BORDER = "error-border";


export default class extends Controller{

  static targets = ["uploadForm", "uploadInput", "validate", "finalize", "validationResults"]

  setState(validate, finalize, results){
    this.validateTarget.disabled = !validate;
    this.finalizeTarget.disabled = !finalize;
    this.validationResultsTarget.style.display = (results ? "block" : "none");
  }

  onFileChange(event){
    // everything is false except validate button toggles
    const fileSelected = (this.uploadInputTarget.files.length > 0);
    this.setState(fileSelected, false, false);
  }

  processUpload(event){

    event.preventDefault();

    event.target.disabled = true;

    const formData = new FormData(this.uploadFormTarget);

    Rails.ajax({
      type: "POST",
      url: event.params.url,
      data: formData,
      success: (response) => {this.displayValidationResults(response)},  // arrow function causes 'this' to be set properly in callback
    });
  }

  displayValidationResults(response){

    if (response.finalized)
      Turbo.visit(response.finalized_url);
    else {
      this.validationResultsTarget.innerHTML = response.result_html;
      this.setState(true, response.success, true);
    }
  }

}


