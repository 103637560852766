
export function setHTML(id, html) {
  // const element = document.getElementById(id);

  // if (element.innerHTML != html)
  //   element.innerHTML = html;

  document.getElementById(id).innerHTML = html;
}


export function enableChildren(parentSelector, state){

  const childSelector = `${parentSelector} *`;

  const children = document.querySelectorAll(childSelector);

  children.forEach((element)=>(element.disabled = !state));
}

